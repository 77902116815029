import React from "react";
import Navbar from "../../components/Navbar";
import NavBarMobile from "../../components/NavBarMobile";
import CmsPage from "../../components/CmsPage";

import { loadPage } from '../../lib/api-lib'

const paths = [];

function Page({ structure }) {
  return (
    <>
      {/* <Navbar navigation={structure.navigation} /> */}
      <NavBarMobile navigation={structure.navigation} />
      {typeof structure.page !== 'undefined' ? 
        <CmsPage structure={structure} />
      : ''}
    </>
  )
}

export async function getStaticProps(context) {
  var pageSlugs = context.params.slug
  let queryParams = '';
  if(!isNaN(pageSlugs[pageSlugs.length-1])) {
    let id = pageSlugs.pop()
    queryParams = '?id=' + id;
  }
  // console.log(pageSlugs.join('/')+queryParams);
  const data = await loadPage(pageSlugs.join('/')+queryParams);
  return {
    props: {
      structure: data,
    },
    revalidate: 1,
  };
}

export async function getStaticPaths() {
    const json = await loadPage() // homepage

    json.navigation.map(function(link) {
      recursiveStaticPathGeneration(link);
    })
  
    // console.log(paths);
    return { paths, fallback: 'blocking' }
}

function recursiveStaticPathGeneration(link) {
  var slugVal;    
  if(typeof link.path != 'undefined') {
    slugVal = link.path
    if(slugVal != '') {
      slugVal = slugVal.split('/')
      slugVal.shift()
      // console.log(slugVal)
      paths.push({ params: { 'slug': slugVal }})
    }
  }
  if(link.navigation.length > 0) {
    link.navigation.map(function(slink) {
      recursiveStaticPathGeneration(slink)
    })
  }
}

export default Page